export default {
    login: "连接钱包",
    m: {
        login: "连接"
    },
    logout:'退出',
    user_amount:'钱包余额(TKM)',
    purse_amount:'账户余额(TKM)',
    ti_xian:'提现',
    ti_xian_jl:'提现记录',
    zd:'最低100TKM提现',
    bu_di:'请输入提现金额，最少不低于100TKM',
    ri_qi:'日期',
    tx_zj:'提现资金(TKM)',
    jy_hx:'提现状态',
    ydz:'已到账',
    tj_je:'奖池总额',
    lk_cy:'立刻参与',
    m_z:'尾数',
    ww:'位命中下列数字',
    jl:'奖励',
    dj_dz:'本期大奖得主诞生',
    ql_jc:'正在清理奖池',
    sh_gm:'请稍后购买',
    g_m:'购买',
    d_c:'单次',
    d_c_yb:'每次只能购买一笔，“立即购买”后会随机产生一组哈希值转换的6位数值，根据6位数值判定用户奖励情况，购买后立即开奖。',
    nd_jh:'您的奖号为',
    n_hd:'您获得',
    n_hd_jl:'奖励',
    hyh_wzj:'很遗憾，本次未中奖',
    hyh_xc:'祝您下次好运',
    hyh_jx:'继续购买',
    lj_1:'连接您的钱包',
    lj_2:'检查您是否中奖',
    lj_3:'连接钱包',
    zj_1:'日期',
    zj_2:'奖号',
    zj_3:'中奖情况',
    zj_4:'奖励金额',
    zj_5:'已中奖',
    zj_6:'未中奖',
    n_wcj:'您未参与',
    n_wcj_lj:'立即参与',
    y_1:'邀请好友',
    y_2:'邀请',
    y_3:'好友',
    b_1:'日期',
    b_2:'被邀请人',
    b_3:'奖励',
    b_jl:'奖励',
    gm_1:'购买流程',
    gm_2:'如果您哈希值上的数字以正确的顺序命中指定规则，您将赢得奖池中的部分奖金',
    gm_3:'第1步',
    gm_4:'参与购买',
    gm_5:'按笔购买，每一笔10tkm，每次限制一笔，购买后立马计算奖励',
    gm_6:'第2步',
    gm_7:'即刻开奖',
    gm_8:'购买后区块链会随机产生一串哈希值，并将其转换为6位数字，平台会根据数字匹配的规则来判断用户是否得奖励',
    gm_9:'奖金规则',
    gm_10:'根据用户获得哈希转换的6位数字尾数判断奖励情况',
    gzy_1:'末尾数字为0/1：奖励 5 TKM',
    gzy_2:'尾数2位数值一样：奖励 30 TKM',
    gzy_3:'尾数3位命中指定数值：奖励 200 TKM',
    gzy_4:'尾数4位命中指定数值：奖励 3000 TKM',
    gzy_5:'尾数5位命中指定数值：奖励奖池总额2.5%(奖金的10%会作为税费打入黑洞销毁)',
    gzy_6:'尾数6位命中指定数值：奖励奖池总额45%(奖金的10%会作为税费打入黑洞销毁)',
    gzy_7:'关于',
    gzy_8:'白皮书',
    user:'用户',
    wallet:'钱包',
    language:'语言',
    buy_now:'立即购买',
    prize_history:'开奖情况',
    not_big_prize:'大奖还未认主，连接您的钱包购买一笔试试运气吧！',
    tx_wdz:'未到账',
    clipboard:'邀请链接已复制到剪切板!',
    clipboard_tip:'请安装TUKE钱包，并复制此链接打开！',
    tip_browser:'请先安装TUKE钱包,否则无法使用DAPP！',
    no_data:'暂无数据',
    msg_err:'交易失败，请重试！',
    err_not_num:'请输入要提现的金额！',
    err_num_les:'提现金额不能少于100！',
    err_num_max:'申请金额不能大于您的账户余额！',
    msg_success:'提现申请成功！',
    create_jc_ze:'当前奖池总额',
    create_title_role:'募资规则',
    create_tip_connect:'请先连接到钱包!',
    create_title_create:'分红记录',
    create_role_1:'1、每个钱包最高只能募资5份，每份10000TKM。',
    create_role_2:'2、奖励池最高允许100个钱包募资，最高500份。',
    create_role_3:'3、从募资入口募资的钱包，将会被合约自动记录分配规则，享有PowerTKM永久奖励分配权益。',
    create_role_4:'4、分配比例将按钱包募资额/总募资额的比例进行分配。',
    create_role_5:'5、2022-11-30日结束募资，PowerTKM项目正式启动。',
    create_role_6:'6、奖池总额达到5000000TKM，将自动关闭募资入口。',
    create_btn:'募资',
    msg_donations_max_num_err:'募资份数不能超过5份',
    msg_donations_num_err:'请填写正确的募资份数',
    msg_donations_num_max_err:'每个钱包最多只能募资5份！',
    create_dnation_amount:'分红金额',
    create_dnation_user:'购买用户',
    create_msg_success:'您的募资已提交成功！请稍后片刻查看结果！',
    msg_not_can_buy:'项目暂未启动!',
    tip_your_wallet:'您的钱包',
    tip_has:'已有',
    tip_has_2:'人募资，已停止募资',
    tip_has_3:'份名额',
    can_donation:'募资',
    can_donation_num:'份',
    you_has_donation:'您已募资',
    has_donations_tip:'份',
    tip_user_max:'每个钱包最多募资5份，您无法继续募资',
    tip_end:'募资已停止',
    has_invite:'已邀请',
    has_invite_num:'人',
    change_chain:'您当前不在Thinkium链，是否切换？',
    help:'帮助',
    tip_donation_not_start:'募资还没有开始',
    tip_donation_not_end:'募资已停止',
    title_reward:'奖励规则',
    create_total_bonus:',共计分红:',
}
