<template>
  <div id="app">
    <PhonePower></PhonePower>
  </div>
</template>

<script>
import PhonePower from './components/PhonePower.vue'

export default {
  name: 'App',
  components: {
    PhonePower
  }
}
</script>

<style>
#app {
  font-family: Source Han Sans CN;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>
