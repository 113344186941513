<template>
  <div class="ssss" id="ssss">
    <loading :active.sync="showLoading"
             :can-cancel="false"
             color="#F4B552"
             background-color="#707070"
             :is-full-page="fullPage"></loading>
    <div class="p-navbar">
      <div class="p-contain">
        <b-navbar style="height: 50px;display: flex;flex-direction: row;justify-content: space-between;"
                  toggleable="lg" type="dark" variant="">
          <b-navbar-brand href="#"><img width="112px" style="margin: 10px;" src="../assets/img/logo.png"></b-navbar-brand>

          <b-navbar class="menu-right" is-nav right>
            <!-- Right aligned nav items -->
            <div class=""></div>
            <div class="p-float-right">

              <b-navbar-nav class="ml-auto" right>
                <div v-b-modal.modallang class="lang-group">
                  <img style="width: 22px;"  src="../assets/img/lang-wap.png">
                </div>

                <!--未登录--开始-->
                <b-navbar-nav v-if="!this.$store.state.is_login" class="flex-center-v">
                  <a class="purse-link" @click="this.userLogin">{{$t('m.login')}}</a>
                </b-navbar-nav>
                <!--未登录--结束-->

                <!--登录--开始-->
                <div v-if="this.$store.state.is_login" class="user-group">
                  <div v-b-modal.modaluser class="user-circle">
                    <img style="width: 22px;" src="../assets/img/user.png">
                  </div>

                </div>


                <div v-if="this.$store.state.is_login" class="pursr-group">
                  <div v-b-modal.modalpurse class="group-circle">
                    <img width="22px" src="../assets/img/purse.png">
                  </div>

                </div>
                <!--登录--结束-->

              </b-navbar-nav>
            </div>
          </b-navbar>

        </b-navbar>
      </div>

      <!--banner三个弹框-开始-->
      <b-modal modal-class="modallang xs" id="modallang" hide-header hide-footer centered>
        <div class="d-block text-center">
          <div class="pmodal-head">
            <div class="pmodal-head-title">
              {{$t('language')}}
            </div>
            <div class="pmodal-head-close" @click="$bvModal.hide('modallang')">
              <img src="../assets/img/close-green.png" alt="">
            </div>
          </div>
          <div class="pmodal-body m-body-lang">
            <div @click="changeLang('en')">English</div>
            <div @click="changeLang('zh')">中文(简体)</div>
<!--            <div @click="changeLang('tw')">中文(繁体)</div>-->
          </div>
        </div>
      </b-modal>

      <b-modal modal-class="modalpurse xs"  v-if="this.$store.state.is_login"  id="modalpurse" hide-header hide-footer centered>
        <div class="d-block text-center">
          <div class="pmodal-head">
            <div class="pmodal-head-title">
              {{$t('wallet')}}
            </div>
            <div class="pmodal-head-close" @click="$bvModal.hide('modalpurse')">
              <img src="../assets/img/close-green.png" alt="">
            </div>
          </div>
          <div class="pmodal-body">
            <div class="zh-user" style="">
              <div class="zh-user-33" @click="log_out()">{{$t('logout')}}</div>
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal modal-class="modaluser xs"  v-if="this.$store.state.is_login"  id="modaluser" hide-header hide-footer centered>
        <div class="d-block text-center">
          <div class="pmodal-head">
            <div class="pmodal-head-title">
              {{$t('user')}}
            </div>
            <div class="pmodal-head-close" @click="$bvModal.hide('modaluser')">
              <img src="../assets/img/close-green.png" alt="">
            </div>
          </div>
          <div class="pmodal-body" style="padding: 30px 0px;box-sizing: border-box;">
            <div class="zh-user" style="">
              <div class="zh-user-1">{{$t('user_amount')}}</div>
              <div class="zh-user-2">{{ account_balance }}</div>
              <div class="zh-user-1">{{$t('purse_amount')}}</div>
              <div class="zh-user-2">{{ user_balance }}</div>
              <!--登录后账户没钱--开始-->
              <div class="zh-user-3" v-if="user_balance<100">{{$t('ti_xian')}}</div>
              <div class="zh-user-4" v-if="user_balance<100">{{$t('zd')}}</div>
              <!--登录后账户没钱--结束-->
              <!--登录后账户有钱--开始-->
              <div v-b-modal.modal-tx v-if="user_balance>=100" class="zh-user-33">{{$t('ti_xian')}}</div>
              <!--登录后账户有钱--开始-->
              <div v-b-modal.modal-tx-list class="zh-user-44">{{$t('ti_xian_jl')}}</div>
            </div>
          </div>
        </div>
      </b-modal>
      <!--banner三个弹框-结束-->


      <!--提现-开始-->
      <b-modal modal-class="modalbuy sm"  v-if="this.$store.state.is_login"   id="modal-tx" hide-header hide-footer centered>
        <div class="d-block text-center">
          <div class="pmodal-head">
            <div class="pmodal-head-title">
              {{$t('ti_xian')}}
            </div>
            <div class="pmodal-head-close" @click="$bvModal.hide('modal-tx')">
              <img src="../assets/img/close-green.png" alt="">
            </div>
          </div>
          <div class="pmodal-body">
<!--            <div class="tx-success" v-show="isShow">提现成功，请在钱包中查看</div>-->

            <b-input type="number"
                     id="tx"
                     v-model="withdraw_value"
                     class="mb-2 mr-sm-2 mb-sm-0 tx-input"
                     v-bind:placeholder="$t('bu_di')"
            ></b-input>
            <button class="pm-buy-btn" @click="do_withdrow()">{{$t('ti_xian')}}</button>
          </div>
        </div>
      </b-modal>
      <!--提现-结束-->


      <!--提现记录-开始-->
      <b-modal size="md" modal-class="modalbuy sm"  v-if="this.$store.state.is_login"   id="modal-tx-list" hide-header hide-footer centered scrollable>
        <div class="d-block text-center">
          <div class="pmodal-head">
            <div class="pmodal-head-title">
              {{$t('ti_xian_jl')}}
            </div>
            <div class="pmodal-head-close" @click="$bvModal.hide('modal-tx-list')">
              <img src="../assets/img/close-green.png" alt="">
            </div>
          </div>
          <div class="pmodal-body">
            <div class="section_8-tx flex-col">
              <div class="text-wrapper_21-tx flex-row">
                <span class="text_64">{{$t('ri_qi')}}</span>
                <span class="text_65-tx">{{$t('tx_zj')}}</span>
                <span class="text_66">{{$t('jy_hx')}}</span>
              </div>

              <div class="ov-s-tx">
                <div class="ov-s-tx-inner">
                  <div v-for="item in list_withdrow" :key="item.address">
                    <div class="text-wrapper_22-tx flex-row">
                      <span class="text_68-tx">{{ item.time_str }}</span>
                      <span class="text_69-tx">{{ item.amount }}</span>
                      <span class="text_70-1" v-if="item.status==1">{{$t('ydz')}}</span>
                      <span class="text_70-1" v-else>{{$t('tx_wdz')}}</span>
                    </div>
                    <div class="kj-line"></div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </b-modal>
      <!--提现记录-结束-->

    </div>
    <div class="banner-bg">
      <div class="banner-bg-con">
        <!--      <div class="bg-img">-->
        <!--        <img src="../assets/img/top-bg.png" alt="">-->
        <!--      </div>-->

        <div class="sj">
          <div class="p-con-banner">
            <MarqueeTips class="p-tip" v-bind:content="quee_str" v-bind:speed="50">

            </MarqueeTips>
            <div class="p-count">
              <div class="p-count-text">{{$t('tj_je')}}</div>
              <div class="prize_count_div">
                <countTo :decimals='2' :duration='2000' :endVal='total_bonus' class="p-count-sz"></countTo>
                <div class="p-count-dw">TKM</div>
              </div>

              <div class="p-count-btn" @click="$bvModal.show('modal-1')">{{$t('lk_cy')}}(10TKM)</div>


              <!--清理奖池弹窗-开始-->
              <b-modal modal-class="modalbuy sm" id="modal-clean" hide-header hide-footer centered
                       style="background-color: none">
                <div class="d-block text-center">
                  <div class="pmodal-head" style="border-bottom:none">
                    <div class="pmodal-head-title">

                    </div>
                    <div class="pmodal-head-close" @click="$bvModal.hide('modal-clean')">
                      <img src="../assets/img/close-green.png" alt="">
                    </div>
                  </div>
                  <div class="pmodal-body" style="position: relative">
                    <img src="../assets/img/p-tip.png" alt="">
                    <div style="font-size: 28px;
                              font-family: Source Han Sans CN;
                              font-weight: 500;
                              color: #FEFCF2;
                              line-height: 39px;
                              text-shadow: -1px 10px 32px rgba(178,37,3,0.64);position: absolute;
                              z-index: 10;
                              bottom: 57px;
                              width: 100%;
                              text-align: center;"> {{$t('dj_dz')}} </br> {{$t('ql_jc')}}</br> {{$t('sh_gm')}}
                    </div>
                  </div>
                </div>

              </b-modal>
              <!--清理奖池弹窗-结束-->


              <!--立刻参与弹框-开始-->
              <b-modal modal-class="modalbuy sm" id="modal-1" hide-header hide-footer centered>
                <div class="d-block text-center">
                  <div class="pmodal-head">
                    <div class="pmodal-head-title">
                      {{$t('g_m')}}
                    </div>
                    <div class="pmodal-head-close" @click="$bvModal.hide('modal-1')">
                      <img src="../assets/img/close-green.png" alt="">
                    </div>
                  </div>
                  <div class="pmodal-body">
                    <div class="pm-img"></div>
                    <div class="buy-price">{{$t('d_c')}}10TKM</div>
                    <button class="pm-buy-btn" @click="buy_ticket()">{{$t('lk_cy')}}</button>
                    <div class="buy-price-tip">
                      {{$t('d_c_yb')}}
                    </div>
                  </div>
                </div>

              </b-modal>
              <!--立刻参与弹框-结束-->

              <!--购买成功--中奖-开始-->
              <b-modal modal-class="modalbuy sm" id="modal-2" hide-header hide-footer centered>
                <div class="d-block text-center">
                  <div class="pmodal-head">
                    <div class="pmodal-head-title">
                      {{$t('g_m')}}
                    </div>
                    <div class="pmodal-head-close" @click="$bvModal.hide('modal-2')">
                      <img src="../assets/img/close-green.png" alt="">
                    </div>
                  </div>
                  <div class="pmodal-body">
                    <div class="pm-hx">
                      <div class="pm-hx-1">{{$t('nd_jh')}}</div>
                      <div class="pm-hx-2">{{ number }}</div>
                    </div>
                    <div v-if="is_win" class="buy-price c-F93C71"> {{$t('n_hd')}} {{ prize }} TKM {{$t('n_hd_jl')}}</div>
                    <div v-if="!is_win" class="buy-price c-F93C71"> {{$t('hyh_wzj')}}</div>
                    <div v-if="!is_win" class="buy-wzj"> {{$t('hyh_xc')}}！</div>
                    <div v-if="is_win" class="qrcode1" style="width: 150px;margin: 0 auto;" ref="qrCodeUrl1"></div>
                    <button @click="buy_ticket()" class="pm-buy-btn">{{$t('hyh_jx')}}</button>
                  </div>
                </div>
              </b-modal>
              <!--购买成功--中奖-结束-->


            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="k-bg">
        <div class="k-title">
          <img height="30px" src="../assets/img/title_left.png" style="margin-right: 10px;">
          {{$t('title_reward')}}
          <img height="30px" src="../assets/img/title_right.png" style="margin-left: 10px;">
        </div>

      <div class="jl-level">
      <div class="level-list">
        <div class="level-top">
          <span class="level-list-1">{{$t('m_z')}} 6{{$t('ww')}}</span>
          <span class="level-list-3">{{$t('jl')}}(TKM)</span>
        </div>
        <div class="level-bottom">
          <span class="level-list-5">({{list_top1}})</span>
          <countTo  class="level-list-3_num" :decimals='2' :duration='2000' :endVal='top_1_bonus'></countTo>
        </div>
      </div>
        <div class="level-list">
          <div class="level-top">
            <span class="level-list-1">{{$t('m_z')}} 5{{$t('ww')}}</span>
            <span class="level-list-3">{{$t('jl')}}(TKM)</span>
          </div>
          <div class="level-bottom">
            <span class="level-list-5">({{list_top2}})</span>
            <countTo  class="level-list-3_num" :decimals='2' :duration='2000' :endVal='top_2_bonus'></countTo>
          </div>
        </div>
        <div class="level-list">
          <div class="level-top">
            <span class="level-list-1">{{$t('m_z')}} 4{{$t('ww')}}</span>
            <span class="level-list-3">{{$t('jl')}}(TKM)</span>
          </div>
          <div class="level-bottom">
            <span class="level-list-5">({{list_top3}})</span>
            <countTo  class="level-list-3_num" :decimals='2' :duration='2000' :endVal='top_3_bonus'></countTo>
          </div>
        </div>
        <div class="level-list">
          <div class="level-top">
            <span class="level-list-1">{{$t('m_z')}} 3{{$t('ww')}}</span>
            <span class="level-list-3">{{$t('jl')}}(TKM)</span>
          </div>
          <div class="level-bottom">
            <span class="level-list-5">({{list_top4}})</span>
            <countTo  class="level-list-3_num" :decimals='2' :duration='2000' :endVal='top_4_bonus'></countTo>
          </div>
        </div>
        <div class="level-list">
          <div class="level-top">
            <span class="level-list-1">{{$t('m_z')}} 2{{$t('ww')}}</span>
            <span class="level-list-3">{{$t('jl')}}(TKM)</span>
          </div>
          <div class="level-bottom">
            <span class="level-list-5">({{list_top5}})</span>
            <countTo  class="level-list-3_num" :decimals='2' :duration='2000' :endVal='top_5_bonus'></countTo>
          </div>
        </div>

    </div>
    </div>
    <div class="k-bg">
      <div class="k-con">
        <div class="k-title">
          <img height="30px" src="../assets/img/title_left.png" style="margin-right: 10px;">
          {{$t('prize_history')}}
          <img height="30px" src="../assets/img/title_right.png" style="margin-left: 10px;">
        </div>
        <!--未登录-开始-->
        <div v-if="!this.$store.state.is_login" class="k-kj">
          <div class="k-tip">{{$t('lj_1')}}<br>{{$t('lj_2')}}！</div>
          <div class="k-btn" @click="this.userLogin">{{$t('lj_3')}}</div>
        </div>
        <!--未登录-结束-->
        <!--        有中奖记录-->
        <div v-else-if="list_data&&list_data.length>0" class="section_8_history flex-col">
          <div class="text-wrapper_history flex-row">
            <span class="text_64_history">{{$t('zj_1')}}</span>
            <span class="text_65_history">{{$t('zj_2')}}</span>
            <span class="text_66_history">{{$t('zj_3')}}</span>
            <span class="text_67_history">{{$t('zj_4')}}(TKM)</span>
          </div>
          <div class="ov-s-history">
            <div class="ov-s-inner-history">
              <div v-for="item in list_data" :key="item.address">
                <div v-if="item.is_win" class="text-wrapper_22 flex-row">
                  <span class="text_68_red">{{ item.time_str }}</span>
                  <span class="text_69_red">{{ item.number }}</span>
                  <span class="text_70-2-red" >{{$t('zj_5')}}</span>
                  <span class="text_71_green" >+&nbsp;{{ item.bonus }}&nbsp;</span>
                </div>
                <div v-else class="text-wrapper_22 flex-row">
                  <span class="text_68">{{ item.time_str }}</span>
                  <span class="text_69">{{ item.number }}</span>
                  <span class="text_70-2">{{$t('zj_6')}}</span>
                  <span class="text_71">&nbsp;{{ item.bonus }}&nbsp;</span>
                </div>
                <div class="kj-line"></div>
              </div>

            </div>
          </div>
        </div>
        <!--      登录后空记录-开始-->
        <div v-else class="k-kj">
          <div class="k-sui">
            <img src="../assets/img/sui-1.png" width="42.5px" alt="">
            <div class="k-sui-tip">{{$t('n_wcj')}}</div>
            <img src="../assets/img/sui-2.png" width="42.5px" alt="">
          </div>
          <div class="k-btn" @click="$bvModal.show('modal-1')">{{$t('n_wcj_lj')}}</div>
        </div>
        <!--      登录后空记录-结束-->
      </div>
    </div>

    <div class="invite-bg" v-if="this.userAddr">
      <div class="invite-con">
        <div class="invite-title">
          <img height="30px" src="../assets/img/title_left.png" style="margin-right: 10px;">
          {{$t('y_1')}}
          <img height="30px" src="../assets/img/title_right.png" style="margin-left: 10px;">
        </div>
        <div class="invite-tip" v-if="this.userAddr">https://www.powertkm.com/?c_id={{userAddr}}</div>
        <div class="invite-tip" v-else>https://www.powertkm.com</div>
        <div class="qrcode" v-if="this.userAddr" ref="qrCodeUrl"></div>
        <div class="invite-btn" @click="copyUrl()">{{$t('y_2')}}</div>
      </div>

      <div class="invite-total">
        <span>· {{$t('has_invite')}}{{ recomm_user_num }}{{$t('has_invite_num')}}，</span>
        <span>{{$t('b_jl')}} {{ recomm_profit_total }}TKM ·</span>
      </div>
      <div class="section_8 flex-col">
        <div class="text-wrapper_21 flex-row">
          <span class="text_64">{{$t('b_1')}}</span>
          <span class="text_65-yq">{{$t('b_2')}}</span>
          <span class="text_66">{{$t('b_3')}}</span>
        </div>
        <div class="kj-line"></div>
        <div class="ov-s" v-if="list_recomm&&list_recomm.length>0">
          <div class="ov-s-inner">
            <div  v-for="item in list_recomm">
              <div class="text-wrapper_22 flex-row">
                <span class="text_68">{{ item.time_str }}</span>
                <span class="text_69-yq">{{ item.user }}</span>
                <span class="text_70">&nbsp;+{{ item.profit }}&nbsp;TKM</span>
              </div>
              <div class="kj-line"></div>
            </div>
          </div>
        </div>
        <div class="ov-s" v-else>
          <div class="ov-s-inner">
            <div style="text-align: center;margin-top: 30px;">{{$t('no_data')}}</div>
          </div>
        </div>

      </div>


    </div>

    <div class="pro-bg">
      <div class="pro-inner">
        <div class="pro-title">
          <img height="30px" src="../assets/img/title_left.png" style="margin-right: 10px;">
          {{$t('gm_1')}}
          <img height="30px" src="../assets/img/title_right.png" style="margin-left: 10px;">
        </div>

        <div class="pro-title-2">
          {{$t('gm_2')}}
        </div>
        <div class="pro-step">
          <div class="pro-step-left">
            <div class="sl-1">
              {{$t('gm_3')}}
            </div>
            <div class="sl-2">
              {{$t('gm_4')}}
            </div>
            <div class="sl-3">
              {{$t('gm_5')}}。
            </div>
          </div>
          <div class="pro-step-right">
            <div class="sl-1">
              {{$t('gm_6')}}
            </div>
            <div class="sl-2">
              {{$t('gm_7')}}
            </div>
            <div class="sl-3">
              {{$t('gm_8')}}
            </div>
          </div>
        </div>

        <div class="pro-line">
        </div>
        <div class="pro-title22">
          <img height="30px" src="../assets/img/title_left.png" style="margin-right: 10px;">
          {{$t('gm_9')}}
          <img height="30px" src="../assets/img/title_right.png" style="margin-left: 10px;">
        </div>

        <div class="pro-title-222">
          {{$t('gm_10')}}
        </div>
        <div class="pro-step22">
          <div class="text-wrapper_10">
            <div class="step_line_div">
              <div class="text_25"></div>
              <span class="paragraph_4">{{$t('gzy_1')}}<br/></span>
            </div>
            <div class="step_line_div">
              <div class="text_25"></div>
              <span class="paragraph_4">{{$t('gzy_2')}}<br/></span>
            </div>
            <div class="step_line_div">
              <div class="text_25"></div>
              <span class="paragraph_4">{{$t('gzy_3')}}<br/></span>
            </div>
            <div class="step_line_div">
              <div class="text_25"></div>
              <span class="paragraph_4">{{$t('gzy_4')}}<br/></span>
            </div>
            <div class="step_line_div">
              <div class="text_25"></div>
              <span class="paragraph_4">{{$t('gzy_5')}}<br/></span>
            </div>
            <div class="step_line_div">
              <div class="text_25"></div>
              <span class="paragraph_4">{{$t('gzy_6')}}<br/></span>
            </div>

          </div>
        </div>
      </div>

      <div class="pro-foot">

        <a style="color: #f4eeff;font-weight: 400;text-decoration: none;" target="_blank"
           href="https://mirror.xyz/0x672b9a756b9E111Af11308f75a1B74bEb05242dd/WySPbQJt_xxxHpvkj4iJvTPP47VJlzuogQW1Ci5jMMA">
          {{$t('gzy_7')}}
        </a>
        <a v-if="lang==='中文(简体)'" style="margin-left: 20px;color: #f4eeff;font-weight: 400;text-decoration: none;"
           href="http://file.powertkm.com/PowerTKM.whitepaper.cn.pdf" target="_blank">
          {{$t('gzy_8')}}
        </a>
        <a v-else style="margin-left: 20px;color: #f4eeff;font-weight: 400;text-decoration: none;"
           href="http://file.powertkm.com/PowerTKM.whitepaper.en.pdf" target="_blank">
          {{$t('gzy_8')}}
        </a>
        <a style="margin-left: 20px;color: #f4eeff;font-weight: 400;text-decoration: none;" target="_blank"
           href="https://mirror.xyz/0x672b9a756b9E111Af11308f75a1B74bEb05242dd/8NtNVUunp-MNGFFz7FMCXT-LcMf9Hwg58O8Zsjhdaj4">
          {{$t('help')}}
        </a>


      </div>
      <div style="width: 100%;display: flex;flex-direction:row;justify-content: center;background: #27262C;padding-bottom: 10px;">
        <a href="https://github.com/TargetDAO/PowerTKM-Contract" target="_blank">
          <img style="width: 20px;margin-right: 10px;" src="../assets/img/github.png" alt="">
        </a>
        <a href="https://twitter.com/powertkm1000000" target="_blank">
          <img style="width: 20px;margin-right: 10px;" src="../assets/img/twitter.png" alt="">
        </a>
        <a href="https://discord.gg/HK6DBKwaYf " target="_blank">
          <img style="width: 20px;margin-right: 10px;" src="../assets/img/discord.png" alt="">
        </a>
        <a href="https://t.me/PowerTKM" target="_blank">
          <img style="width: 20px;margin-right: 10px;" src="../assets/img/telegram.png" alt="">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {checkMetaMask, getContract} from "@/web3";
import Marquee from "@/components/marquee/Marquee.vue";
import countTo from 'vue-count-to';
import Web3 from "web3";
import BigNumber from "bn.js";
import MarqueeTips from 'vue-marquee-tips'
import QRCode from 'qrcodejs2'
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: "PhonePower",
  components: {
    // 注册跑马灯组件
    Marquee,
    countTo,
    Loading,
    MarqueeTips,
  },
  data() {
    return {
      showLoading: false,
      fullPage: true,
      isShow: false,
      show: false,
      amount: 0,
      old_num: 0,
      user_addr: '',
      number: 0,
      last_num: 0,
      is_win: false,
      prize: 0,
      is_success: false,
      withdraw_value:null,
      lang: '中文(简体)',
      list_quee: [this.$t('not_big_prize'),this.$t('not_big_prize'),this.$t('not_big_prize'),],
      contract: null,
      quee_str:this.$t('not_big_prize')+"  "+this.$t('not_big_prize')+"  "+this.$t('not_big_prize'),
      about_us_link:'https://mirror.xyz/0x672b9a756b9E111Af11308f75a1B74bEb05242dd/WySPbQJt_xxxHpvkj4iJvTPP47VJlzuogQW1Ci5jMMA',
    }
  },

  computed: {
    // quee_str(){
    //   if(this.$store.getters.list_big_prize&&this.$store.getters.list_big_prize.length>0){
    //       return this.$store.getters.list_big_prize;
    //   }else{
    //     return [this.$t('not_big_prize'),this.$t('not_big_prize'),this.$t('not_big_prize'),];
    //   }
    // },
    is_login() {
      return this.$store.getters.is_login;
    },
    user_balance() {
      return this.$store.getters.user_balance;
    },
    userAccount() {
      return this.$store.getters.account.substring(0, 5) + "***" + this.$store.getters.account.substring(38, 42);
    },
    userAddr() {
      return this.$store.getters.account;
    },
    account_balance() {
      return this.$store.getters.addr_balance;
    },
    list_withdrow() {
      return this.$store.getters.list_withdrow;
    },
    total_bonus() {
      return this.$store.getters.total_bonus/(10**18);
    },
    top_1_bonus() {
      return (this.$store.getters.total_bonus * 0.45)/(10**18);
    },
    top_2_bonus() {
      return (this.$store.getters.total_bonus * 0.025)/(10**18);
    },
    top_3_bonus() {
      return 3000;
      return (this.$store.getters.total_bonus * 0.003)/(10**18);
    },
    top_4_bonus() {
      return 200;
      return (this.$store.getters.total_bonus * 0.0002)/(10**18);
    },
    top_5_bonus() {
      return 30;
      return (this.$store.getters.total_bonus * 0.0002)/(10**18);
    },
    list_data() {
      return this.$store.getters.list_user_order;
    },
    list_recomm() {
      return this.$store.getters.list_recomm;
    },
    list_top1() {
      return this.$store.getters.list_top1_num;
    },
    list_top2() {
      return this.$store.getters.list_top2_num;
    },
    list_top3() {
      return this.$store.getters.list_top3_num;
    },
    list_top4() {
      return this.$store.getters.list_top4_num;
    },
    list_top5() {
      return this.$store.getters.list_top5_num;
    },
    recomm_user_num() {
      return this.$store.getters.recomm_user_num;
    },
    recomm_profit_total() {
      return this.$store.getters.recomm_profit_total;
    },

  },
  // 当前Vue组件被创建时回调的hook 函数
  async created() {
    const that = this;

    document.title='PowerTKM';
    const hasMetaMask = await checkMetaMask();
    if (!hasMetaMask) {
      this.$alert(this.$t('tip_browser'));
      return;
    }
    await this.check_chain();
    ethereum.on("chainChanged",function (chainId){
       that.check_chain();
    });

    ethereum.on("accountsChanged", function(accounts) {
      that.initUserStatus();
    });


    switch (localStorage.getItem('locale')){
      case 'en':
        this.lang='English';
        break;
      case 'zh':
        this.lang='中文(简体)';
        break;
      case 'tw':
        this.lang='中文(繁体)';
        break;
      default:
        this.lang='中文(繁体)';
        break;
    }
    this.$i18n.locale = localStorage.getItem('locale')
    switch (localStorage.getItem('locale')) {
      case 'en':
        this.lang = 'English';
        break;
      case 'zh':
        this.lang = '中文(简体)';
        break;
      case 'tw':
        this.lang = '中文(繁体)';
        break;
    }


    let c_id=this.$urlParse.getUrlKey("c_id")
    localStorage.setItem("c_id",c_id)



  },
  methods: {
    async check_chain(){
      let chainId = await ethereum.request({method: "eth_chainId"});
      const ropstenChainId = "0x11171";//正式链
      // const ropstenChainId = "0xea61";//测试链
      // console.log(chainId)
      if (chainId !== ropstenChainId) {
        this.$confirm(this.$t('change_chain')).then(() => {
          if (!(this.$store.dispatch("switchNetwork"))) {

          }
        });

      }else {
        await this.load_init_data();
      }
    },
    async load_init_data(){
      const that = this;
      await this.$store.dispatch("getTopNum");
      await this.initUserStatus()
      var big_prize_size=0;
      setInterval(async function () {
        await that.$store.dispatch("getTotalBonus");
        await that.$store.dispatch("getTopNum");
        await that.$store.dispatch("getBigPrizeList");
        if(that.$store.getters.list_big_prize.length>big_prize_size){
          big_prize_size=that.$store.getters.list_big_prize.length;
          that.quee_str="";
          for (let i = 0; i < that.$store.getters.list_big_prize.length; i++) {
            that.quee_str=that.quee_str+that.$store.getters.list_big_prize[i]+" ";
          }

        }
        if (that.$store.state.is_login){
          await that.$store.dispatch("getUserBalance");
          await that.$store.dispatch("getWithdrawList");
          await that.$store.dispatch("getRecommList");
          await that.getList();
        }
      }, 2000);

      await this.$store.dispatch("getTotalBonus");
      if (this.$store.state.is_login){
        await this.$store.dispatch("getUserBalance");
        await this.$store.dispatch("getWithdrawList");
        await this.$store.dispatch("getRecommList");
        // await this.setQrcode();
        await this.getList();
      }
      const time_now = new Date().getTime();

      if(time_now<(1669824000*1000)){
        that.$alert(that.$t('msg_not_can_buy'));
        return;
      }
    },
    async getList() {
      this.contract = await getContract();
      await this.$store.dispatch("getTicketList");
    },

    async setQrcode(){
      const qrcode_div = document.getElementsByClassName('qrcode')[0]
      const url='https://www.powertkm.com/?c_id='+this.userAddr;
      if(qrcode_div){
        while (qrcode_div.firstChild) {
          qrcode_div.removeChild(qrcode_div.firstChild)
        }
        new QRCode(this.$refs.qrCodeUrl, {
          text: url, // 需要转换为二维码的内容
          width: 150,
          height: 150,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
        })
      }
    },
    async setQrcodeOnDialog(){
      const qrcode_div = document.getElementsByClassName('qrcode1')[0]
      const url='https://www.powertkm.com/?c_id='+this.userAddr;
      if(qrcode_div){
        while (qrcode_div.firstChild) {
          qrcode_div.removeChild(qrcode_div.firstChild)
        }
        new QRCode(this.$refs.qrCodeUrl1, {
          text: url, // 需要转换为二维码的内容
          width: 150,
          height: 150,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
        })
      }
    },


    async copyUrl() {
      const str_url = 'https://www.powertkm.com/?c_id='+this.userAddr+' '+this.$t('clipboard_tip');
      const that=this;
      this.$copyText(str_url).then(function(){
        that.$alert(that.$t('clipboard'));
      }, function(e) {

      });
    },
    async buy_ticket() {
      const that = this;
      if (!this.$store.state.is_login) {
        this.userLogin();
        const is_login = await this.$store.dispatch("checkIfConnected");
        if (is_login) {
          await this.$store.dispatch("getUserBalance");
          await this.$store.dispatch("getUserAmount");
        }
        return;
      }


      const time_now = new Date().getTime();


      if(time_now<(1669824000*1000)){
      // if(time_now<(1669559400*1000)){
        that.$alert(that.$t('msg_not_can_buy'));
        return;
      }
      // that.$showLoading('ssss');
      that.showLoading = true;
      const provider = window.ethereum;
      // 请求用户账号授权
      // 如果未授权就会弹出下图授权界面, 如果已授权就跳过了
      const web3 = new Web3(provider);
      const contract = await this.$store.dispatch("getContract");
      let cid=localStorage.getItem("c_id");
      if(cid===null||cid==='null'){
        cid=this.$store.state.account;
      }
      let maxGas=21000;
      await contract.methods.play(cid).estimateGas({from: this.$store.state.account})
          .then(function(gasAmount){
            console.log('gasAmount:'+gasAmount);
            maxGas=gasAmount;
          })
          .catch(function(error){
            console.log('error:'+error);
          });
      contract.methods.play(cid).send({
        from: this.$store.state.account,
        value: web3.utils.toWei("10", 'ether'),
        // gasLimit: web3.utils.toHex(maxGas), // 提高Gas上限
        gasLimit: web3.utils.toHex(10000000), // 提高Gas上限
        gasPrice: web3.utils.toHex(web3.utils.toWei('400', 'gwei')),
      }, function (err, result) {
        if (err) {
          console.log(err)
          // that.$hideLoading();
          that.showLoading = false
        }
      }).then(result => {
        // that.$hideLoading();
        that.showLoading = false
        console.log(result.events.PlayResult.returnValues)
        if (result.events.PlayResult && result.events.PlayResult.returnValues) {
          const result_data = result.events.PlayResult.returnValues;
          this.is_win = result_data.is_win;
          this.number = result_data.number.padStart(6, '0');
          this.prize = result_data.prize/(10**18);
          this.$store.dispatch("getTotalBonus")
          this.$store.dispatch("getTicketList");
          this.$store.dispatch("getUserAmount");
          this.$store.dispatch("getWithdrawList");
          this.$store.dispatch("getRecommList");
          this.$bvModal.hide('modal-1')
          this.$bvModal.show('modal-2')
          setTimeout(function (){
            // that.setQrcodeOnDialog();
          },1000);

        }
      }).catch(function (error) {
        console.log(error)
        that.$alert(that.$t('msg_err'));
        // that.$hideLoading();
        that.showLoading = false
      });

    },
    async initUserStatus() {
      const is_login = await this.$store.dispatch("checkIfConnected");
      if (is_login) {
        await this.$store.dispatch("getUserBalance");
        await this.$store.dispatch("getUserAmount");
        await this.$store.dispatch("getWithdrawList");
        await this.$store.dispatch("getRecommList");
      }

    },
    async changeLang(type) {
      this.$bvModal.hide('modallang');
      this.$i18n.locale = type
      localStorage.setItem("locale", type);
      switch (type) {
        case 'en':
          this.lang = 'English';
          break;
        case 'zh':
          this.lang = '中文(简体)';
          break;
        case 'tw':
          this.lang = '中文(繁体)';
          break;
      }
    },
    async log_out() {
      this.$store.commit("set_login", false);
      this.$store.commit("setAccount", "");
      this.$bvModal.hide('modalpurse')
    },
    async withdrow() {
      this.$bvModal.show('modal-tx')
    },
    async do_withdrow() {
      if(this.withdraw_value<=0){
        this.$alert(this.$t('err_not_num'));
        return;
      }
      if(this.withdraw_value<100){
        this.$alert(this.$t('err_num_les'));
        return;
      }
      if(this.withdraw_value>this.user_balance){
        this.$alert(this.$t('err_num_max'));
        return;
      }
      const that = this;
      that.showLoading = true;
      const provider = window.ethereum;
      const web3 = new Web3(provider);
      const contract = await this.$store.dispatch("getContract");
      contract.methods.userWithdraw(this.withdraw_value).send({
        from: this.$store.state.account,
        gasLimit: web3.utils.toHex(5000000), // 提高Gas上限
        gasPrice: web3.utils.toHex(web3.utils.toWei('400', 'gwei')),
      }, function (err, result) {
        if (err) {
          that.showLoading = false
        }
      }).then(result => {
        that.showLoading = false
        // console.log(result.events.PlayResult.returnValues)
        that.$alert(this.$t('msg_success'));
        that.$store.dispatch("getUserBalance");
        that.$store.dispatch("getUserAmount");
        that.$store.dispatch("getWithdrawList");
      }).catch(function (error) {
        console.log(error)
        that.$alert(that.$t('msg_err'));
        that.showLoading = false
      });

      // await this.$store.dispatch("withdraw", this.withdraw_value);
    },
    async userLogin() {
      await this.$store.dispatch("connect", 1);
      // await this.setQrcode();
      await this.$store.dispatch("getWithdrawList");
    },
  }
}
</script>

<style scoped>
.vld-overlay.is-full-page{
  z-index: 999999;
}
.ssss{
  max-width: 720px !important;
  margin: 0 auto !important;
}
.m-body-lang {
  margin: 30px;
}

.m-body-lang div {
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #d3d3d3;
}

.jl-level {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 15px;
  align-items: center;
}

.level-list {
  width: 95%;
  margin-top: 10px;
  overflow-wrap: break-word;
  font-size: 0;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  background: #FFFFFF;
  border: 1px solid #E6E3EB;
  border-radius: 10px;
  padding: 10px;
}

.level-list-1 {
  height: 30px;
  overflow-wrap: break-word;
  color: #2E85FE;
  font-size: 14px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
}

.level-list-11 {
  width: 50px;
  height: 30px;
  overflow-wrap: break-word;
  color: #2E85FE;
  font-size: 14px;
  font-family: SourceHanSansCN-Bold;
  text-align: left;
}

.level-list-2 {
  width: 50px;
  height: 30px;
  overflow-wrap: break-word;
  color: #2E85FE;
  font-size: 14px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
}

.level-list-3 {
  height: 30px;
  overflow-wrap: break-word;
  color: #2E85FE;
  font-size: 9px;
  font-family: SourceHanSansCN-Bold;
  text-align: left;
  line-height: 20px;
}
.level-list-3_num{
  font-size: 18px;
  color: #2E85FE;
  font-weight: bold;
}
.level-list-5 {
  height: 30px;
  overflow-wrap: break-word;
  color: #7287AB;
  font-size: 14px;
  text-align: left;
}

.ssss {
  position: relative;
  width: 100%;
  margin: 0px auto;
  background: #f7f7f7;
}

template {
  width: 750px;
}

.pro-foot {
  height: 40px;
  background: #27262C;
  text-align: center;
  font-size: 14px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #AFABB7;
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.text-wrapper_10 {
  width: 100%;
  overflow-wrap: break-word;
  font-size: 0;
  font-family: SourceHanSansCN-Heavy;
  text-align: left;
  line-height: 25px;

}

.text_25 {
  width: 5px;
  height: 5px;
  background: #2E85FE;
  border-radius: 50%;
  margin-top: 5px;
}

.paragraph_4 {
  width: 96%;
  overflow-wrap: break-word;
  color: #7287ab;
  font-size: 13px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  line-height: 13px;
  margin-left: 4px;
}


.pro-bg {
  width: 100%;
}

.pro-title {
  width: 319px;
  height: 38px;
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #0764FF;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.pro-inner {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pro-step-left {
  width: 247px;
  height: 141px;
  background: #FFFFFE;
  border: 2px solid #E6E3EB;
  box-shadow: 0px 1px 0px 0px #e6e3eb;
  border-radius: 10px;
  padding: 14px 9px;
  margin-left: 10px;
}

.pro-step-right {
  width: 247px;
  height: 141px;
  background: #FFFFFE;
  border: 2px solid #E6E3EB;
  box-shadow: 0px 1px 0px 0px #e6e3eb;
  border-radius: 10px;
  padding: 14px 9px;
  margin-left: 10px;
  margin-right: 10px;
}

.sl-1 {
  width: 100%;
  height: 18px;
  font-size: 9px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #301A63;
  line-height: 30px;
  text-align: right;
}

.sl-2 {
  font-size: 10px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #0764ff;
  margin-top: 7px;
  margin-bottom: 5px;
}

.sl-3 {
  font-size: 10px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #7287ab;
  line-height: 15px;
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
}

.pro-step {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 35px;
  white-space: nowrap;
  overflow-x: auto;
}

.pro-title-2 {
  width: 85%;
  height: 71px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #0764ff;
  line-height: 28px;
  text-align: center;
  letter-spacing: 1px;
}

.pro-title22 {
  /* width: 143px; */
  height: 35px;
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  text-align: center;
  color: #0764FF;
  line-height: 24px;
}

.pro-title-222 {
  height: 23px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #0764FF;
  line-height: 24px;
  margin-top: 10px;
  margin-bottom: 21px;
}

.pro-step22 {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff;
  border: 2px solid #E6E3EB;
  border-radius: 10px;
  padding: 10px;
}

.pro-step22-left {
  width: 444px;
  height: 428px;
}

.pro-step22-left img {
  width: 444px;
  height: 428px;
}


.ov-s::-webkit-scrollbar {
  display: none;
}




.section_8-tx {
  background-color: rgba(255, 255, 255, 0.11);
  border-radius: 20px;
  width: 100%;
  height: 556px;
  margin: 20px auto 0 auto;
}

.text-wrapper_21-tx {
  width: 100%;
  height: 40px;
  background: #E8EDF6;
  opacity: 1;
  line-height: 40px;
  border-radius: 20px 20px 0px 0px;
}

.section_8 {
  background-color: rgba(255, 255, 255, 0.11);
  border-radius: 20px;
  width: 90%;
  height: 556px;
  margin: 70px auto 0 auto;
}

.text-wrapper_21 {
  width: 100%;
  height: 37px;
  background: #E8EDF6;
  opacity: 1;
  border-radius: 20px 20px 0px 0px;
}

.text-wrapper_history {
  width: 100%;
  height: 40px;
  background: #0764FF;
  opacity: 1;
  border-radius: 10px 10px 0px 0px;
}
.text_64_history{
  width: 48px;
  height: 17px;
  overflow-wrap: break-word;
  color: #FFFFFF;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 40px;
  margin: 0px 0 0 30px;
}


.text_65-tx {
  width: 60px;
  height: 18px;
  overflow-wrap: break-word;
  color: #6593E0;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 37px;
  margin: 23px 0 0 47px;
}

.text_66 {
  width: 80px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 37px;
  margin: 23px 0 0 187px;
}




.text_68-tx {
  width: 172px;
  height: 18px;
  overflow-wrap: break-word;
  color: #776DA5;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 48px;
}

.text_69-tx {
  width: 92px;
  height: 14px;
  overflow-wrap: break-word;
  color: #776DA5;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 65px;
  margin: 0px 55px 0 35px;
}

.text_70-1 {
  width: 159px;
  height: 14px;
  overflow-wrap: break-word;
  color: #776DA5;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: center;
  white-space: nowrap;
  line-height: 48px;
  margin: 1px 51px 0 19px;
}


.invite-btn {
  width: 110px;
  height: 40px;
  background: #FFB750;
  border-radius: 22px;
  font-size: 15px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #FFFFFF;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.invite-tip {
  height: 33px;
  width: 90%;
  line-break: anywhere;
  font-size: 18px;
  font-family: Myriad Pro;
  font-weight: 400;
  color: #0764FF;
  margin: 34px auto 38px auto;
}

.invite-total{
  text-align: center;
  font-size: 16px;
  color: #0764FF;
  font-weight: bold;
}
.invite-title {
  height: 38px;
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #0764FF;
}

.invite-con {
  width: 100%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0px;
}

.invite-bg {
  width: 100%;
  height: 1050px;
}


.ov-s::-webkit-scrollbar {
  display: none;
}


.ov-s-tx::-webkit-scrollbar {
  display: none;
}

.ov-s-tx-inner {
  width: 100%;
  height: 484px;
  border: #27262C 1px;
}

.ov-s-tx {
  overflow-y: scroll;
  width: 100%;
  height: 484px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}


.section_8 {
  background-color: rgba(255, 255, 255, 0.11);
  border-radius: 20px;
  width: 90%;
  height: 556px;
  margin: 70px auto 0 auto;
}

.section_8_history {
  background: #0764FF;
  border-radius: 10px;
  width: 95%;
  height: 556px;
  margin: 70px auto 0 auto;
}

.text_65_history {
  width: 60px;
  height: 18px;
  overflow-wrap: break-word;
  color: #fff;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 37px;
  margin: 23px 0 0 90px;
}





.text_69 {
  width: 72px;
  height: 14px;
  overflow-wrap: break-word;
  color: #000;
  font-size: 10px;
  display: inline-block;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 37px;
  margin: 2px 0 0 20px;
}

.text_69_red {
  width: 72px;
  height: 14px;
  overflow-wrap: break-word;
  color: #F4B552;
  font-size: 10px;
  display: inline-block;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 37px;
  margin: 2px 0 0 20px;
}

.text_70 {
  height: 14px;
  display: inline-block;
  overflow-wrap: break-word;
  color:#000;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: center;
  white-space: nowrap;
  margin: 1px 0 0 40px;
}


.k-sui-tip {
  margin: 0px 15px;
  font-size: 15px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #7287AB;
  line-height: 55px;
}

.k-sui {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.k-tip {
  font-size: 15px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #7287AB;
  text-align: center;
}

.k-btn {
  width: 132px;
  height: 38px;
  background: #FFB750;
  border-radius: 19px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 38px;
  text-align: center;
  margin-top: 40px;
}

.k-con {
  width: 100%;
  height: auto;
  margin: 0px auto;
}

.k-bg {
  display: flex;
  width: 100%;
  background: #F7F7F7;
  flex-direction: column;
}

.k-kj {
  width: 90%;
  height: 245px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
}

.k-title {
  width: auto;
  height: 39px;
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  text-align: center;
  color: #0764FF;
  margin: 35px auto 35px auto;
}


.buy-wzj {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #6C5E91;
  margin-top: 30px;
}

.c-F93C71 {
  color: #0764FF !important;
}

.pm-hx-1 {
  margin-top: 30px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #6C5E91;
  line-height: 52px;
}

.pm-hx-2 {
  height: 68px;
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: 600;
  color: #343a40;
}

.pmodal-head-close {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.buy-price-tip {
  width: 80%;
  height: 54px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #6C5E91;
  line-height: 20px;
  opacity: 0.76;
  margin: 0px auto 30px auto;
}

button.pm-buy-btn {
  width: 182px;
  height: 52px;
  background: #FFB750;
  border-radius: 22px;
  border: none;
  margin: 40px auto;
  font-size: 21px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 38px;
}

.buy-price {
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #6C5E91;
}

.pmodal-head-title {
  width: calc(100% - 30px);
  text-align: center;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #29135E;
}

.pmodal-head {
  width: 100%;
  height: 75px;
  border-bottom: 1px solid #EDEFF4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pm-img {
  width: 162px;
  height: 145px;
  background-image: url(../assets/img/piao.png);
  background-repeat: no-repeat;
  background-position: 42% 32%;
}

.modalbuy {
  width: 320px !important;
  background: #6F47D1;
}

.banner-bg-con {
  width: 100%;
  margin: 0px auto;
}

.p-con-banner {
  display: flex;
  flex-direction: column;
}

.banner-bg {
  position: relative;
  width: 100%;
  height: 300px;
  background: url(../assets/img/top-bg.png) center center no-repeat;
  background-size: cover;
  background-position: center bottom;
}

.bg-img {
  width: 100%;
  height: 521px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
}

.bg-img img {
  width: 100%;
  height: 521px;
}

.p-count-sz {
  font-size: 36px;
  font-family: Myriad Pro;
  font-weight: bold;
  color: #ffffff;
  background: linear-gradient(0deg, #ffffff 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.p-tip {
  width: 351px;
  height: 26px;
  background: #241734;
  opacity: 0.5;
  border-radius: 20px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  color: #FFFFFF;
  line-height: 26px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  overflow-x: hidden;
  margin: 20px auto 0px auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}


.p-tip::-webkit-scrollbar {
  display: none;
}

.p-count {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.p-count-text {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FDFFFF;
  line-height: 41px;
  margin-top: 40px;
}

.p-count-dw {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 800;
  color: #FFFFFF;
}

.p-count-btn {
  width: 180px;
  height: 44px;
  background: #FFB750;
  border-radius: 22px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0px 3px 7px rgba(0,0,0,0.35);
  text-align: center;
  margin-top: 12px;
  line-height: 44px;
}

@media only screen and (max-width: 500px) {
  .p-count-dw {
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 800;
    color: #FFFFFF;
    margin-left: 20px;
  }
}


.tx-success {
  width: 80%;
  height: 70px;
  background: #000000;
  opacity: 0.62;
  border-radius: 10px;
  text-align: center;
  line-height: 70px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  position: absolute;
  left: 10%;
  z-index: 100;
}

.ov-s::-webkit-scrollbar {
  display: none;
}

.ov-s-inner {
  width: 100%;
  height: 474px;
}

.ov-s {
  overflow-y: scroll;
  width: 100%;
  height: 495px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.ov-s-history {
  overflow-y: scroll;
  width: 95%;
  height: 495px;
  background: #FFFFFF;
  border-radius: 10px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin: 0 auto;
}
.ov-s-inner-history {
  width: 95%;
  height: 495px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.kj-line {
  width: 100%;
  height: 2px;
  background: #EEEFF4;
  opacity: 0.33;
}

.section_8 {
  background-color: #fff;
  border-radius: 20px;
  width: 90%;
  height: 550px;
  margin: 20px auto 20px auto;
  padding-bottom: 10px;
  border: 1px solid #EEEFF4;
}

.text_64 {
  width: 48px;
  height: 17px;
  overflow-wrap: break-word;
  color: #6593E0;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 40px;
  margin: 0px 0 0 15px;
}

.text_65-yq {
  width: 60px;
  height: 18px;
  overflow-wrap: break-word;
  color: #6593E0;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 48px;
  margin: 23px 82px 0 85px;
}

.text_66 {
  width: 80px;
  height: 18px;
  overflow-wrap: break-word;
  color: #6593E0;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 37px;
  margin: 23px 0 0 43px;
}
.text_66_history {
  width: 80px;
  height: 18px;
  overflow-wrap: break-word;
  color: #fff;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 37px;
  margin: 23px 0 0 43px;
}
.text_67_history {
  width: 82px;
  height: 18px;
  overflow-wrap: break-word;
  color: #fff;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 37px;
  margin: 22px 0px 0 20px;
}

.text-wrapper_22 {
  width: 100%;
  height: 34px;
  margin: 0px 10px 0px 10px;
}

.text_68 {
  /*width: 96px;*/
  height: 18px;
  overflow-wrap: break-word;
  color: #000;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 37px;
}
.text_68_red {
  width: 96px;
  height: 18px;
  overflow-wrap: break-word;
  color: #F4B552;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 37px;
}
.text_69-yq {
  width: 92px;
  height: 20px;
  overflow-wrap: break-word;
  color: #000;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 37px;
  margin: 2px 20px 0 15px;
}

.text_70-2 {
  height: 20px;
  overflow-wrap: break-word;
  color: #000;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: center;
  white-space: nowrap;
  line-height: 37px;
  margin: 1px 0 0 10px;
}

.text_70-2-red {
  height: 20px;
  overflow-wrap: break-word;
  color: #F4B552;
  font-size: 10px;
  font-family: SourceHanSansCN-Medium;
  text-align: center;
  white-space: nowrap;
  line-height: 37px;
  margin: 1px 0 0 10px;
}

.text_71 {
  width: 103px;
  height: 18px;
  overflow-wrap: break-word;
  color: #000;
  font-size: 10px;
  font-family: SourceHanSansCN-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 37px;
  margin-left: 36px;
}

.text_71_green {
  width: 103px;
  height: 18px;
  overflow-wrap: break-word;
  color: #F4B552;
  font-size: 10px;
  font-family: SourceHanSansCN-Bold;
  text-align: left;
  white-space: nowrap;
  line-height: 38px;
  margin-left: 36px;
}

.tx-input {
  width: 90%;
  margin: 40px auto 20px auto;
  height: 51px;
}

.buy-wzj {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #6C5E91;
  margin-top: 30px;
}

.c-F93C71 {
  color: #0764FF !important;
}

.pm-hx-1 {
  margin-top: 30px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #6C5E91;
  line-height: 52px;
}

.pm-hx-2 {
  height: 68px;
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: 600;
  color: #343a40;
}

.pmodal-head-close {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.buy-price-tip {
  width: 80%;
  height: 54px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #A1AFC6;
  line-height: 20px;
  opacity: 0.76;
  margin: 0px auto 30px auto;
}

button.pm-buy-btn {
  width: 182px;
  height: 52px;
  background: #FFB750;
  border-radius: 22px;
  border: none;
  margin: 40px auto;
  font-size: 21px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 38px;
}

.buy-price {
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #0764FF;
}

.pmodal-head-title {
  width: calc(100% - 30px);
  text-align: center;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
}

.pmodal-head {
  width: 100%;
  height: 75px;
  border-bottom: 1px solid #EDEFF4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pm-img {
  width: 100%;
  height: 200px;
  background-image: url(../assets/img/piao.png);
  background-repeat: no-repeat;
  background-position: 50% 32%;
}

.modalbuy {
  width: 320px !important;
  background: #6F47D1;
}


.zh-user-33 {
  width: 120px;
  height: 40px;
  background: #FFB750;
  border-radius: 22px;
  text-align: center;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 40px;
  margin: 15px;
}

.zh-user-44 {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  text-decoration: underline;
  color: #6C5E91;
}

.zh-user-1 {
  margin: 20px auto 15px auto;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #29135E;
}

.zh-user-2 {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #0764FF;
}

.zh-user-3 {
  width: 90px;
  height: 40px;
  background: rgba(164, 161, 163, 0.4);
  border-radius: 20px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #6C5E91;
}

.zh-user-4 {
  /* width: 108px; */
  /* height: 14px; */
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #0764FF;
  line-height: 55px;
}

.zh-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.zh-inner {
  width: 167px;
  height: 203px;
  background: #FFFFFF;
  opacity: 0.95;
  border-radius: 20px;
}

.user-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin: 0px 15px;
  /* border: 2px solid #fff; */
  width: 22px;
  height: 22px;
  /* background: #48464E; */
  border-radius: 15px;
  /* margin-top: 3px; */
  position: relative;
}

.user-circle {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  text-align: center;
  line-height: 17px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.group-circle {
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 15px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.pursr-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  /*border: 2px solid #fff;*/
  width: 22px;
  height: 22px;
  /*background: #48464E;*/
  /*border-radius: 15px;*/
  position: relative;
  margin-right: 10px;
}

.dropdown-toggle::after {
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}


.p-contain {
  width: 100%;
  margin: 0px auto;
  padding: 0px 5px;
}

.p-navbar {
  width: 100%;
  height: 50px;
  box-shadow: 0px -1px 0px 0px #373240;
}

.menu-right {
  justify-content: end;
}

.p-float-right {
  float: right;
}

.p-contain .navbar {
  --bs-navbar-padding-y: 0px;
}

.purse-link {
  width: 80px;
  height: 30px;
  background: #FFB750;
  border-radius: 15px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
}

.flex-center-v {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.p-float-right ul {
  align-items: center;
}

.lang-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: relative;
  width: 22px;
  height: 22px;
}

.lang-group img {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 22px;
  height: 22px;
}

.c-fff {
  color: #fff !important;
}
.step_line_div{
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.level-top{
  display: flex;
  justify-content: space-between;
}
.level-bottom{
  display: flex;
  justify-content: space-between;
}
.prize_count_div{
  display: flex;
  align-items: center;
}
</style>
