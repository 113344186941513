<template>
  <!-- 跑马灯组件 -->
  <div class="marquee-wrap" ref="marquee-wrap">
    <div class="scroll" ref="scroll">
      <p class="marquee" v-html="text"></p>
      <p class="copy" ref="copy"></p>
    </div>
    <p class="getWidth" ref="getWidth" v-html="text"></p>
  </div>
</template>

<script>
export default {
  name: 'Marquee',
  props: ['val'],
  data () {
    return {
      timer: null,
      text: ''
    }
  },
  created () {
    let timer = setTimeout(() => {
      this.move()
      clearTimeout(timer)
    }, 1000)
  },
  mounted () {
    for (let item of this.val) {
      this.text += "<span style='font-size: 13px !important;'>"+item+"</span>";
    }
  },
  methods: {
    move () {
      let maxWidth = this.$refs['marquee-wrap'].clientWidth
      let width = this.$refs['getWidth'].scrollWidth
      if (width <= maxWidth) return
      let scroll = this.$refs['scroll']
      let copy = this.$refs['copy']
      copy.innerHtml = this.text
      let distance = 0
      this.timer = setInterval(() => {
        distance -= 1
        if (-distance >= width) {
          distance = 16
        }
        scroll.style.transform = 'translateX(' + distance + 'px)'
      }, 20)
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>

<style scoped>
.marquee-wrap {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.marquee{
  margin-right: 0.16rem;
}
p {
  word-break:keep-all;
  white-space: nowrap;
  font-size: 0.28rem;
}
.scroll {
  display: flex;
}
.getWidth {
  word-break:keep-all;
  white-space:nowrap;
  position: absolute;
  opacity: 0;
  top: 0;
}
</style>