export default {

        login:"連接錢包",

        m: {

                login:"連接"

        },

        logout: '退出',

        user_amount: '錢包餘額（TKM）',

        purse_amount: '帳戶餘額（TKM）',

        ti_xian: '提現',

        ti_xian_jl: '提現記錄',

        zd: '最低100TKM提現',

        bu_di: '請輸入提現金額,最少不低於100TKM',

        ri_qi: '日期',

        tx_zj: '提現資金（TKM）',

        jy_hx: '提現狀態',

        ydz: '已到賬',

        tj_je: '獎池總額',

        lk_cy: '立刻參與',

        m_z: '命中',

        ww: '比特',

        dj_dz: '本期大獎得主誕生',

        ql_jc: '正在清理獎池',

        sh_gm: '請稍後購買',

        g_m: '購買',

        d_c: '單次',

        d_c_yb: '每次只能購買一筆,“立即購買”後會隨機產生一組雜湊值轉換的6比特數值,根據6比特數值判定用戶獎勵情况,購買後立即開獎。',

        nd_jh: '您的獎號為',

        n_hd: '您獲得',

        n_hd_jl: '獎勵',

        hyh_wzj: '很遺憾,本次未中獎',

        hyh_xc: '祝您下次好運',

        hyh_jx: '繼續購買',

        lj_1: '連接您的錢包',

        lj_2: '檢查您是否中獎',

        lj_3: '連接錢包',

        zj_1: '日期',

        zj_2: '獎號',

        zj_3: '中獎情況',

        zj_4: '獎勵金額',

        zj_5: '已中獎',

        zj_6: '未中獎',

        n_wcj: '您未參與',

        n_wcj_lj: '立即參與',

        y_1: '邀請好友',

        y_2: '邀請',

        y_3: '好友',

        b_1: '日期',

        b_2: '被邀請人',

        b_3: '獎勵',

        b_jl: '獎勵',

        gm_1: '購買流程',

        gm_2: '如果您雜湊值上的數位以正確的順序命中指定規則,您將贏得獎池中的部分獎金',

        gm_3: '第1步',

        gm_4: '參與購買',

        gm_5: '按筆購買,每一筆10tkm,每次限制一筆,購買後馬上計算獎勵',

        gm_6: '第2步',

        gm_7: '即刻開獎',

        gm_8: '購買後區塊鏈會隨機產生一串雜湊值,並將其轉換為6比特數位,平臺會根據數位匹配的規則來判斷用戶是否得獎勵',

        gm_9: '獎金資金',

        gm_10: '根據用戶獲得雜湊轉換的6比特數位尾數判斷獎勵情况',

        gzy_1: '末尾數位為0/1：獎勵5 TKM',

        gzy_2: '尾數2比特數值一樣：獎勵30 TKM',

        gzy_3: '尾數3比特數值一樣：獎勵200 TKM',

        gzy_4: '尾數4比特數值一樣：獎勵3000 TKM',

        gzy_5: '尾數5比特數值連號：獎勵獎池總額2.5%',

        gzy_6: '尾數6比特數值連號：獎勵獎池總額45%',

        gzy_7: '關於項目',

        gzy_8: '下載白皮書PDF',

        user: '用戶',
        jl:'獎勵',
        wallet: '錢包',

        language: '語言',

        buy_now: '立即購買',

        prize_history: '開獎情况',

        not_big_prize: '大獎還未認主,連接您的錢包購買一筆試試運氣吧！',

        tx_wdz: '未到賬',

        clipboard: '邀請連結已複製到剪切板！',

        tip_browser: '請先安裝MetaMask,否則無法使用DAPP！',

        no_data: '暫無數據',

}