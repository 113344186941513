import Vue from 'vue'
import App from './App-wap.vue'
import store from "./store";
import i18n from './lang/index.js'
import loadingViewerVue from 'loading-view-vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueSimpleAlert from "vue-simple-alert";
import VueAnimateNumber from 'vue-animate-number'
// import 'lib-flexible'
import urlParse  from './urlParse'
import VueClipboard from 'vue-clipboard2'
if(localStorage.getItem('locale')!=null){
  i18n.locale = localStorage.getItem('locale')
}else{
  localStorage.setItem('locale','en')
  i18n.locale = 'en'
}
Vue.use(VueAnimateNumber)
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(loadingViewerVue)
Vue.use(VueClipboard)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import axios from 'axios'
Vue.prototype.$http= axios
Vue.use(VueSimpleAlert);
Vue.config.productionTip = false
Vue.prototype.$urlParse=urlParse   //注册全局方法
new Vue({
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
