import Vue from "vue";
import Vuex from "vuex";
import {userLogin, checkMetaMask, getUserBalance, web3} from '../web3'
import Web3 from "web3";
import lottery from "@/lottery";
import moment from 'moment';
import * as constants from "constants";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        web3: null,
        account: null,
        is_login: false,
        user_balance: 0,
        addr_balance: 0,
        total_bonus: 0,
        list_user_order:null,
        list_user_donations:null,
        list_withdrow:null,
        list_recomm:null,
        list_top1_num:'',
        list_top2_num:'',
        list_top3_num:'',
        list_top4_num:'',
        list_top5_num:'',
        recomm_user_num:0,
        recomm_profit_total:0,
        user_donation_num:0,
        total_donation_num:0,
        total_donation_user:0,
        user_proportion:0,
        list_big_prize:[],
    },
    getters: {
        account: (state) => state.account,
        is_login: (state) => state.is_login,
        user_balance: (state) => state.user_balance,
        addr_balance: (state) => state.addr_balance,
        total_bonus: (state) => state.total_bonus,
        web3: (state) => state.web3,
        list_user_order: (state) => state.list_user_order,
        list_withdrow: (state) => state.list_withdrow,
        list_recomm: (state) => state.list_recomm,
        list_big_prize: (state) => state.list_big_prize,
        list_top1_num: (state) => state.list_top1_num,
        list_top2_num: (state) => state.list_top2_num,
        list_top3_num: (state) => state.list_top3_num,
        list_top4_num: (state) => state.list_top4_num,
        list_top5_num: (state) => state.list_top5_num,
        recomm_user_num: (state) => state.recomm_user_num,
        recomm_profit_total: (state) => state.recomm_profit_total,
        user_donation_num: (state) => state.user_donation_num,
        total_donation_num: (state) => state.total_donation_num,
        total_donation_user: (state) => state.total_donation_user,
        user_proportion: (state) => state.user_proportion,
        list_user_donations: (state) => state.list_user_donations,
    },
    mutations: {
        setAccount(state, account) {
            state.account = account;
        },
        set_login(state, n) {
            state.is_login = n;
        },
        set_user_balance(state, n) {
            state.user_balance = n;
        },
        set_web3(state, n) {
            state.web3 = n;
        },
        set_total_bonus(state, n) {
            state.total_bonus = n;
        },
        set_list_user_order(state, n) {
            state.list_user_order = n;
        }
        ,set_list_user_donations(state, n) {
            state.list_user_donations = n;
        },
        set_addr_balance(state, n) {
            state.addr_balance = n;
        },
        set_list_withdrow(state, n) {
            state.list_withdrow = n;
        },
        set_list_recomm(state, n) {
            state.list_recomm = n;
        },
        set_list_top1_num(state, n) {
            state.list_top1_num = n;
        },
        set_list_top2_num(state, n) {
            state.list_top2_num = n;
        },
        set_list_top3_num(state, n) {
            state.list_top3_num = n;
        },
        set_list_top4_num(state, n) {
            state.list_top4_num = n;
        },
        set_list_top5_num(state, n) {
            state.list_top5_num = n;
        },
        set_recomm_user_num(state, n) {
            state.recomm_user_num = n;
        },
        set_recomm_profit_total(state, n) {
            state.recomm_profit_total = n;
        },
        set_list_big_prize(state, n) {
            state.list_big_prize = n;
        },
        set_user_donation_num(state, n) {
            state.user_donation_num = n;
        },
        set_total_donation_num(state, n) {
            state.total_donation_num = n;
        },
        set_user_proportion(state, n) {
            state.user_proportion = n;
        },
        set_total_donation_user(state, n) {
            state.total_donation_user = n;
        },

    },
    actions: {

        async getDonationsList({commit, dispatch}) {
            const contract = await dispatch("getContract");
            contract.methods.getUserDonations().call({ from: this.state.account}).then(result => {
                // console.log(result)
                const list_result=new Array();
                if(result&&result.length>0){
                    for (let i = 0; i < result.length; i++) {
                        const item=JSON.parse(JSON.stringify(result[i]));
                        item.id=result[i].id;
                        item.user=result[i].user.substring(0, 5) + "***" + result[i].user.substring(38, 42);
                        item.add_time=result[i].add_time;
                        item.amount=result[i].amount/(10**18);
                        const time_str=moment(item.add_time*1000).format('YYYY-MM-DD HH:mm');
                        item.time_str=time_str;
                        list_result.push(item);
                    }
                    list_result.reverse();
                    commit("set_list_user_donations", list_result);
                }
                // console.log(list_result)

            })
        },
        async getDonationsData({commit, dispatch}) {
            const contract = await dispatch("getContract");
            contract.methods.getDonationsTotal().call().then(result => {
                // console.log(result)
                commit("set_total_donation_num", result);
            })
            contract.methods.getDonationsUserTotal().call().then(result => {
                // console.log(result)
                commit("set_total_donation_user", result);
            })
        },
        async getUserDonationsData({commit, dispatch}) {
            const contract = await dispatch("getContract");
            contract.methods.getUserDonationNum().call({ from: this.state.account}).then(result => {
                // console.log(result)
                commit("set_user_donation_num", result);
            })
            contract.methods.getUserProportion().call({ from: this.state.account}).then(result => {
                // console.log(result)
                commit("set_user_proportion", result);
            })
        },

        async checkUserLogin({commit, dispatch}) {
            let chainId = await ethereum.request({method: "eth_chainId"});
            const ropstenChainId = "0x61";
            if (chainId !== ropstenChainId) {
                if (!(await dispatch("switchNetwork"))) {
                    commit(
                        "setError",
                        "You are not connected to the Ropsten Test Network!"
                    );
                }
            }
        },
        async withdraw({commit, dispatch},number){
            const contract = await dispatch("getContract");
            contract.methods.userWithdraw(number).send({
                from: this.$store.state.account,
            }, function (err, result) {
                if (err) {
                    that.$hideLoading();
                }
            }).then(result => {
                console.log(result)
            })
        },
        async getTotalBonus({commit, dispatch}) {
            const contract = await dispatch("getContract");
            contract.methods.getTotalBonus().call().then(result => {
                // console.log(result)
                commit("set_total_bonus", result);
            })
        },
        async getUserAmount({commit, dispatch}) {
            const contract = await dispatch("getContract");
            contract.methods.getUserBalance().call({ from: this.state.account}).then(result => {
                commit("set_user_balance", result/(10**18));
            })
        },
        async getTicketList({commit, dispatch}) {
            const contract = await dispatch("getContract");
            contract.methods.getTicketList(0,0).call({ from: this.state.account}).then(result => {
                // console.log(result)
                const list_result=new Array();
                if(result&&result.length>0){
                    for (let i = 0; i < result.length; i++) {
                        const item=JSON.parse(JSON.stringify(result[i]));
                        item.id=result[i].id;
                        item.time=result[i].time;
                        item.number=result[i].number.padStart(6, '0');
                        item.is_win=result[i].is_win;
                        item.bonus=result[i].bonus/(10**18);
                        const time_str=moment(item.time*1000).format('YYYY-MM-DD HH:mm');
                        item.time_str=time_str;
                        list_result.push(item);
                    }
                    list_result.reverse();
                    commit("set_list_user_order", list_result);
                }
                // console.log(list_result)

            })
        },
        async getTopNum({commit, dispatch}) {
            const contract = await dispatch("getContract");
            contract.methods.getTopNumList(0).call({ from: this.state.account}).then(result => {
                let num_str=" ";
                for (let i = 0; i < result.length; i++) {
                    num_str=num_str+result[i].padStart(6, '0').padStart(6, '*');
                    if(i<result.length-1){
                        num_str=num_str+' , ';
                    }
                }
                commit("set_list_top1_num", num_str);
            })
            contract.methods.getTopNumList(1).call({ from: this.state.account}).then(result => {
                let num_str=" ";
                for (let i = 0; i < result.length; i++) {
                    num_str=num_str+result[i].padStart(5, '0').padStart(6, '*');
                    if(i<result.length-1){
                        num_str=num_str+' , ';
                    }

                }
                commit("set_list_top2_num", num_str);
            })
            contract.methods.getTopNumList(2).call({ from: this.state.account}).then(result => {
                let num_str=" ";
                for (let i = 0; i < result.length; i++) {
                    num_str=num_str+result[i].padStart(4, '0').padStart(6, '*');
                    if(i<result.length-1){
                        num_str=num_str+' , ';
                    }
                }
                commit("set_list_top3_num", num_str);
            })
            contract.methods.getTopNumList(3).call({ from: this.state.account}).then(result => {
                let num_str=" ";
                for (let i = 0; i < result.length; i++) {
                    num_str=num_str+result[i].padStart(3, '0').padStart(6, '*');
                    if(i<result.length-1){
                        num_str=num_str+' , ';
                    }
                }
                commit("set_list_top4_num", num_str);
            })
            contract.methods.getTopNumList(4).call({ from: this.state.account}).then(result => {
                let num_str=" ";
                for (let i = 0; i < result.length; i++) {
                    num_str=num_str+result[i].padStart(2, '0').padStart(6, '*');
                    if(i<result.length-1){
                        num_str=num_str+' , ';
                    }
                }
                commit("set_list_top5_num", num_str);
            })
        },
        async getWithdrawList({commit, dispatch}) {
            const contract = await dispatch("getContract");
            contract.methods.getWithdrawList(0,0).call({ from: this.state.account}).then(result => {
                // console.log(result)
                const list_result=new Array();
                if(result&&result.length>0){
                    for (let i = 0; i < result.length; i++) {
                        const item=JSON.parse(JSON.stringify(result[i]));
                        item.time=result[i].time;
                        item.user=result[i].user;
                        item.amount=result[i].amount;
                        item.status=result[i].status;
                        const time_str=moment(item.time*1000).format('YYYY-MM-DD HH:mm');
                        item.time_str=time_str;
                        list_result.push(item);
                    }
                    list_result.reverse();
                    commit("set_list_withdrow", list_result);
                }
                // console.log(list_result)

            })
        },
        async getBigPrizeList({commit, dispatch}) {
            const contract = await dispatch("getContract");
            contract.methods.getBigPrizeList().call({ from: this.state.account}).then(result => {
                // console.log(result)
                const list_result=new Array();
                if(result&&result.length>0){
                    for (let i = 0; i < result.length; i++) {
                        var check_id=result[i].id-1;
                        if(result[i].id==0){
                            check_id=103;
                        }
                        contract.methods.getTicket(check_id).call({ from: this.state.account}).then(result => {
                            const item=JSON.parse(JSON.stringify(result));
                            item.user=result.user.substring(0, 5) + "***" + result.user.substring(38, 42);
                            item.time=result.time;
                            item.number=result.number.padStart(6, '0');
                            item.is_win=result.is_win;
                            item.bonus=result.bonus/(10**18);
                            const time_str=moment(item.time*1000).format('YYYY-MM-DD HH:mm');
                            item.time_str=time_str;
                            const quee_str= time_str+" Congratulations on "+item.user +" winning "+item.bonus+' TKM\t';
                            list_result.push(quee_str);
                        })

                    }
                    commit("set_list_big_prize", list_result);
                }
                // console.log(list_result)

            })
        },
        async getOneTicket({commit, dispatch}) {
            const contract = await dispatch("getContract");
            contract.methods.getBigPrizeList().call({ from: this.state.account}).then(result => {
                // console.log(result)
                const list_result=new Array();
                if(result&&result.length>0){
                    for (let i = 0; i < result.length; i++) {
                        const item=JSON.parse(JSON.stringify(result[i]));
                        item.user=result[i].user.substring(0, 5) + "***" + result[i].user.substring(38, 42);
                        item.time=result[i].time;
                        item.number=result[i].number.padStart(6, '0');
                        item.is_win=result[i].is_win;
                        item.bonus=result[i].bonus/(10**18);
                        const time_str=moment(item.time*1000).format('YYYY-MM-DD HH:mm');
                        item.time_str=time_str;
                        const quee_str= time_str+" 恭喜"+item.user +"获得"+item.bonus+'TKM';
                        list_result.push(quee_str);
                    }
                    commit("set_list_big_prize", list_result);
                }
                // console.log(list_result)

            })
        },
        async getRecommList({commit, dispatch}) {
            const contract = await dispatch("getContract");
            contract.methods.getRecommProfitList(0,0).call({ from: this.state.account}).then(result => {
                // console.log(result)
                const list_result=new Array();
                var total_profit=0;
                const arr_addr=[];
                if(result&&result.length>0){
                    for (let i = 0; i < result.length; i++) {
                        const item=JSON.parse(JSON.stringify(result[i]));
                        item.time=result[i].time;
                        item.user=result[i].user.substring(0, 5) + "***" +result[i].user.substring(30, 42);
                        item.recomm_user=result[i].recomm_user;
                        item.number=result[i].number;
                        item.is_win=result[i].is_win;
                        item.bonus=result[i].bonus/(10**18);
                        item.profit=result[i].profit;
                        const time_str=moment(item.time*1000).format('YYYY-MM-DD HH:mm');
                        item.time_str=time_str;
                        if(!arr_addr.includes(result[i].user)){
                            arr_addr.push(result[i].user);
                        }
                        total_profit=total_profit+parseFloat(result[i].profit);
                        list_result.push(item);
                    }
                    list_result.reverse();

                }
                // console.log(list_result)

                commit("set_recomm_user_num", arr_addr.length);
                commit("set_recomm_profit_total", total_profit);
                commit("set_list_recomm", list_result);
            })
        },
        async buyTicket({commit, dispatch}) {
            const provider = window.ethereum;
            // 请求用户账号授权
            // 如果未授权就会弹出下图授权界面, 如果已授权就跳过了
            const web3 = new Web3(provider);
            const contract = await dispatch("getContract");
            contract.methods.play().send({
                from: this.state.account,
                value: web3.utils.toWei("1", 'ether')
            },function (err,result){
                if(err){
                    return false;
                    // that.$hideLoading();
                }
                // this.amount = web3.utils.fromWei(result, 'ether');
            }).then(result=>{
                // that.$hideLoading();
                console.log(result.events.PlayResult.returnValues)
                if(result.events.PlayResult&&result.events.PlayResult.returnValues){
                    const result_data=result.events.PlayResult.returnValues;
                    this.is_win=result_data.is_win;
                    this.number=result_data.number;
                    this.prize=result_data.prize;
                    dispatch("getTotalBonus")
                    dispatch("getTicketList");


                }
                return true;
            });
        },
        async getUserBalance({commit, dispatch}) {
            const provider = window.ethereum;
            // 请求用户账号授权
            // 如果未授权就会弹出下图授权界面, 如果已授权就跳过了
            const web3 = new Web3(provider);
            commit("set_web3", web3);
            const coinbase = await web3.eth.getAccounts();
            const user_addr = coinbase[0];
            // console.log(user_addr)
            let balance = 0;
            if (user_addr) {
                balance = await web3.eth.getBalance(user_addr); // 账户余额
                balance = web3.utils.fromWei(balance, 'ether');

            }


            const user_balance = parseFloat(balance).toFixed(3);
            commit("set_addr_balance", user_balance);
        },

        async connect({commit, dispatch}, connect) {
            try {
                const {ethereum} = window;
                if (!ethereum) {
                    // 验证是否安装 Metamask
                    commit("setError", "Metamask not installed!");
                    return;
                }
                if (!(await dispatch("checkIfConnected")) && connect) {
                    // 验证连接状态
                    await dispatch("requestAccess");
                }
                await dispatch("requestAccess");
                await dispatch("checkNetwork"); // 检查选择的网络

                await dispatch("getUserBalance"); // 获取用户钱包余额
                await dispatch("getTotalBonus");
                await dispatch("getTicketList");
                await dispatch("getUserAmount");
                await dispatch("getWithdrawList");
                await dispatch("getRecommList");
                commit("set_login", true)

            } catch (error) {
                console.log(error);
                commit("setError", "Account request refused.");
            }
        },
        async checkNetwork({commit, dispatch}) {
            let chainId = await ethereum.request({method: "eth_chainId"});
            // const ropstenChainId = "0x61";
            // if (chainId !== ropstenChainId) {
            //     if (!(await dispatch("switchNetwork"))) {
            //         commit(
            //             "setError",
            //             "You are not connected to the Ropsten Test Network!"
            //         );
            //     }
            // }
        },
        async switchNetwork() {

            await ethereum
                .request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainId: '0x11171',
                            chainName: 'Thinkium',
                            nativeCurrency: {
                                name: 'TKM',
                                symbol: 'TKM',
                                decimals: 18,
                            },
                            rpcUrls: ['https://proxy1.thinkiumrpc.net'],
                            blockExplorerUrls: ['https://www.thinkiumscan.net'],
                        },
                    ],
                })
                .then(() => {
                    return 1;
                })
                .catch((e) => {
                    return 0;
                })

        },
        async checkIfConnected({commit}) {
            const {ethereum} = window;
            const accounts = await ethereum.request({method: "eth_accounts"});
            if (accounts.length !== 0) {
                // commit("setAccount", accounts[0]);
                return 1;
            } else {
                return 0;
            }
        },
        async requestAccess({commit}) {
            const {ethereum} = window;
            const accounts = await ethereum.request({
                method: "eth_requestAccounts",
            });
            commit("setAccount", accounts[0]);
        },
        async getContract({state}) {
            try {
                const provider = window.ethereum;
                // 请求用户账号授权
                // 如果未授权就会弹出下图授权界面, 如果已授权就跳过了
                const web3 = new Web3(provider);
                const contractAddress = lottery.contract; // 合约地址
                const abi = lottery.abi; // 合约abi，相当于合约提供的对接文档
                const contract = new web3.eth.Contract(abi, contractAddress);
                // 调用合约方法
                return contract;
            } catch (error) {
                console.log(error);
                console.log("connected contract not found");
                return null;
            }
        },

    },
})

