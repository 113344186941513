import Vue from 'vue';
import VueI18n from 'vue-i18n'

import en from './en'
import zh from './zh'
import tw from './tw'

Vue.use(VueI18n);

const messages = {
    en: Object.assign(en),
    tw: Object.assign(tw),
    zh: { ...zh }
}

// localStorage.setItem('locale', 'zh')
const i18n = new VueI18n({
    locale: localStorage.getItem('locale') || "zh",
    messages
})


export default i18n;
