import Web3 from "web3";
import lottery from "@/lottery";
var web3;
async function checkMetaMask(){
    if (typeof window.ethereum !== 'undefined') {

        return true;
    } else {
        console.log('Please install MetaMask')

        return false;
    }
}
async function userLogin(){

    try {
        const provider = window.ethereum;
        // 请求用户账号授权
        // 如果未授权就会弹出下图授权界面, 如果已授权就跳过了
        await provider.enable();
        web3 = new Web3(provider);
        // web3 = new Web3(new Web3.providers.HttpProvider('http://localhost:7545'));
        return true;
    } catch (error) {
        console.log('User denied account access');
        return false;
    }
}

async function getUserBalance(){
    const coinbase= await web3.eth.getAccounts();
    const user_addr=coinbase[0];
    // console.log(user_addr)
    let balance=0;
    if (user_addr) {
        balance = await web3.eth.getBalance(user_addr); // 账户余额
        balance = web3.utils.fromWei(balance, 'ether');

    } else {
        return 0;
    }
    // console.log("用户余额："+balance);
    return balance;

}

async function getContract(){

    const provider = window.ethereum;
    // 请求用户账号授权
    // 如果未授权就会弹出下图授权界面, 如果已授权就跳过了
    await provider.enable();
    web3 = new Web3(provider);

    const contractAddress = lottery.contract; // 合约地址
    const abi = lottery.abi; // 合约abi，相当于合约提供的对接文档
    const contract = new web3.eth.Contract(abi, contractAddress);
    // 调用合约方法
    return  contract;
}



export { userLogin, checkMetaMask ,getContract,getUserBalance,web3};